import React, { Component } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import style from "./NavHeader.module.scss";

class NavHeader extends Component {
    state = {
        expanded: false,
        links: [
            { type: "anchor", url: "#section-1", name: "Pitch" },
            { type: "anchor", url: "#section-2", name: "Managed" },
            { type: "anchor", url: "#pricing", name: "Pricing" },
            { type: "anchor", url: "#section-3", name: "About" },
            { type: "modal", url: "#section-3", name: "Contact" },
        ],
    };

    toggleExpansion = () => {
        this.setState((state) => {
            return {
                expanded: !state.expanded,
            };
        });
    };

    render() {
        const { toggleModal } = this.props;
        const { expanded } = this.state;
        // Dom, you could use a filter here to change the function of the element for type == modal
        // But we need the modal pulled up to exist at layout state level

        const navbar_items = this.state.links.map((link) => {
            if (link.type === "anchor") {
                return (
                    <AnchorLink
                        className={`navbar-item ${style.navbarItem}`}
                        href={link.url}
                        key={link.name}
                        onClick={this.toggleExpansion}
                    >
                        {link.name}
                    </AnchorLink>
                );
            } else {
                return (
                    <button
                        className={`navbar-item ${style.navbarItem}`}
                        key={link.name}
                        onClick={() => {
                            this.toggleExpansion();
                            toggleModal();
                        }}
                    >
                        {link.name}
                    </button>
                );
            }
        });

        return (
            <nav
                className={`navbar ${style.navbar}`}
                role="navigation"
                aria-label="main navigation"
            >
                {/* <div className="navbar-brand">
                If we want a logo here?
            </div> */}
                <button
                    //nav-burger
                    className={`navbar-burger burger ${style.navBurger} ${
                        expanded ? "is-active" : ""
                    }`}
                    aria-label="menu"
                    aria-expanded={expanded ? "true" : "false"}
                    onClick={this.toggleExpansion}
                >
                    <span aria-hidden="true" />
                    <span aria-hidden="true" />
                    <span aria-hidden="true" />
                </button>

                <div
                    className={`navbar-menu ${style.navbarMenu} ${
                        expanded ? "is-active" : ""
                    }`}
                >
                    {/*navbar-items */}
                    <div className={`navbar-end ${style.navbarItems}`}>
                        {navbar_items}
                    </div>
                </div>
            </nav>
        );
    }
}

export default NavHeader;
