import React, { Component } from "react";
import Modal from "./Modal";

import { FaAt, FaUser, FaSmile } from "react-icons/fa";
import { IconContext } from "react-icons";

const initialState = {
    name: "",
    email: "",
    website: "",
    currentProvider: "",
    chosenPackage: "",
    message: "",
};

class ContactModal extends Component {
    state = initialState;

    resetState = () => {
        this.setState(initialState);
    };

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value,
        });
    };

    componentWillUnmount() {
        if (this.toggleTimeout) {
            window.clearTimeout(this.toggleTimeout);
        }
    }

    toggleModal = () => {
        // Reset Modal on close - can change to just reset the package ...
        this.toggleTimeout = window.setTimeout(
            this.setState(initialState),
            600
        );
        this.props.toggle();
    };

    render() {
        const { initialPackage = "", ...rest } = this.props;
        const {
            name,
            email,
            website,
            currentProvider,
            chosenPackage,
            message,
        } = this.state;

        const modalProps = { ...rest, toggle: this.toggleModal };
        return (
            <Modal {...modalProps}>
                <p className="is-size-4">We'd love to hear from you!</p>
                    <form
                        name="contact"
                        method="post"
                        action="/thanks"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                    >
                        <input type="hidden" name="form-name" value="contact" />
                        <div className="field is-horizontal">
                            <div className="field-body">
                                <div className="field">
                                    <div className="control has-icons-left has-icons-right">
                                        <input
                                            className="input"
                                            type="text"
                                            placeholder="Name"
                                            name="Name"
                                            id="name"
                                            onChange={this.handleChange}
                                            value={name}
                                            required
                                        />
                                        <span className="icon is-left">
                                            <FaUser />
                                        </span>
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="control has-icons-left has-icons-right">
                                        <input
                                            className="input"
                                            type="email"
                                            placeholder="Email"
                                            name="email"
                                            id="email"
                                            onChange={this.handleChange}
                                            value={email}
                                            required
                                        />
                                        <span className="icon is-large is-left">
                                            <FaAt />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="field is-horizontal">
                            <div className="field-body">
                                <div className="field">
                                    <div className="control has-icons-left has-icons-right">
                                        <input
                                            className="input"
                                            type="text"
                                            placeholder="Website"
                                            name="website"
                                            id="website"
                                            onChange={this.handleChange}
                                            value={website}
                                            required
                                        />
                                        <span className="icon is-left" />
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="control has-icons-left has-icons-right">
                                        <input
                                            className="input"
                                            type="text"
                                            placeholder="Current Provider"
                                            name="current_provider"
                                            id="currentProvider"
                                            onChange={this.handleChange}
                                            value={currentProvider}
                                            required
                                        />
                                        <span className="icon is-left" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="field is-horizontal">
                            <div className="field-body">
                                <div className="field">
                                    <div className="control has-icons-left">
                                        <div className="select">
                                            <select
                                                name="package"
                                                id="chosenPackage"
                                                onChange={this.handleChange}
                                                value={
                                                    initialPackage !== "" &&
                                                    chosenPackage === ""
                                                        ? initialPackage
                                                        : chosenPackage
                                                }
                                                required
                                            >
                                                <option value="">Package</option>
                                                <option value="shared">Shared</option>
                                                <option value="upsize">Upsize</option>
                                                <option value="lone-wolf">
                                                    Lone Wolf
                                                </option>
                                                <option value="other">Other</option>
                                            </select>
                                        </div>
                                        <span className="icon is-left" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <textarea
                                    name="message"
                                    className="textarea has-fixed-size"
                                    placeholder="Your wishlist"
                                    rows="5"
                                    id="message"
                                    onChange={this.handleChange}
                                    value={message}
                                    required
                                />
                            </div>
                        </div>
                        <p className="control" style={{ textAlign: `right` }}>
                            <button
                                className="button is-large"
                                type="submit"
                            >
                                Let's roll
                                <IconContext.Provider
                                    value={{ className: "custom-icon" }}
                                >
                                    <FaSmile />
                                </IconContext.Provider>
                            </button>
                        </p>
                    </form>
            </Modal>
        );
    }
}

export default ContactModal;
