import React, { Component, Fragment } from "react";

const expandedStyle = {
    visibility: `visible`,
    opacity: 1,
    // Should be enough height for lots of content ...
    maxHeight: `200vh`,
    transition: `all 500ms ease`,
};

const hiddenStyle = {
    visibility: `hidden`,
    opacity: 0,
    maxHeight: 0,
    transition: `all 500ms ease`,
};

class Toggle extends Component {
    state = {
        expanded: false,
    };

    toggleExpansion = () => {
        this.setState((state) => ({
            expanded: !state.expanded,
        }));
    };

    render() {
        const { children, toggleText } = this.props;
        const { expanded } = this.state;

        return (
            <Fragment>
                <button
                    className="button"
                    onClick={this.toggleExpansion}
                    style={expanded ? { marginBottom: `2.5rem` } : {}}
                    aira-pressed={expanded ? 'true' : 'false'}
                    aria-expanded={expanded ? 'true' : 'false'}
                >
                    {expanded ? toggleText.hide : toggleText.expand}
                </button>
                <div style={expanded ? expandedStyle : hiddenStyle}>
                    {children}
                </div>
            </Fragment>
        );
    }
}

export default Toggle;
