import React, { Component } from "react";

import "./style.scss";
import Helmet from "./helmet";
import NavHeader from "./NavHeader";
import Header from "./header";
import Content from "./content";
import ContactModal from "./ContactModal";

class Layout extends Component {
    state = {
        modalVisible: false,
        initialPackage: "",
    };

    toggleModal = (initialPackage = "") => {
        this.setState((state) => ({
            modalVisible: !state.modalVisible,
            initialPackage: initialPackage
                ? initialPackage
                : state.initialPackage,
        }));
    };

    render() {
        return (
            <div>
                <NavHeader toggleModal={this.toggleModal} />
                <Helmet />
                <Header />
                <Content toggleModal={this.toggleModal} />

                <ContactModal
                    visible={this.state.modalVisible}
                    toggle={this.toggleModal}
                    initialPackage={this.state.initialPackage}
                />
            </div>
        );
    }
}

export default Layout;
