import React from "react";
const visibleStyle = {
    visibility: `visible`,
    opacity: 1,
    transition: `all 500ms ease`
};

const invisibleStyle = {
    visibility: `hidden`,
    opacity: 0,
    transition: `all 500ms ease`
}

const Modal = ({ visible, toggle, children }) => (
    <div className="modal is-active" style={visible ? visibleStyle : invisibleStyle }>
        <div className="modal-background" onClick={toggle} />
        <div className="modal-content">{children}</div>
        <button
            className="modal-close is-large"
            aria-label="close"
            onClick={toggle}
        />
    </div>
);

export default Modal;
