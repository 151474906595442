import React, { Fragment } from "react";
import { StaticQuery, graphql } from "gatsby";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Img from "gatsby-image";

import { FaChevronDown } from "react-icons/fa";
import Toggle from "./Toggle";

const Content = ({ toggleModal }) => (
    <StaticQuery
        query={graphql`
            query {
                carl: file(relativePath: { eq: "carl-new.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 650) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                dominik: file(relativePath: { eq: "dominik.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 650, cropFocus: NORTH) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                steven: file(relativePath: { eq: "steven.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 650) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `}
        render={({ carl, dominik, steven }) => (
            <Fragment>
                <div>
                    <section
                        className="hero secondary is-fullheight"
                        id="section-1"
                    >
                        <div className="hero-body">
                            <div className="container">
                                <div className="columns is-multiline">
                                    <div className="column is-full">
                                        <p className="is-size-1">
                                            The Elevator Pitch
                                        </p>
                                    </div>
                                    <div className="column is-four-fifths-desktop">
                                        <p className="is-size-5">
                                            As WordPress developers we have
                                            experienced the painful deficiencies
                                            of current providers. Hosting is not
                                            a commodity, although our
                                            competitors treat it as one. We
                                            don't jam you all on one server
                                            running plesk or cpanel and hope for
                                            the best.
                                        </p>
                                        <p className="is-size-5">
                                            Your site will always be online,
                                            secure and running blisteringly
                                            fast. We routinely update your
                                            WordPress stack, scan for malware,
                                            tweak firewalls and automate
                                            backups, letting you concentrate on
                                            writing content or shipping product.
                                        </p>

                                        <p className="is-size-5">
                                            We don't own hardware, we use
                                            Amazon's, we pay more so you get
                                            more. Harnessing the expertise of a
                                            tech monolith lets us punch well
                                            above our weight. We treat
                                            infrastructure as code, allowing us
                                            to scale fluidly as your site grows.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <AnchorLink className="more" href="#section-2">
                                <FaChevronDown />
                            </AnchorLink>
                        </div>
                    </section>
                    <section className="hero primary" id="section-2">
                        <div className="hero-body">
                            <div className="container">
                                <div className="columns is-multiline">
                                    <div className="column is-full">
                                        <h1 className="is-size-1">
                                            Truly Managed
                                        </h1>
                                    </div>
                                    <div className="column is-four-fifths-desktop">
                                        <p className="is-size-5">
                                            We update your WordPress stack via a
                                            custom staging and testing process.
                                            We compare your updated site with
                                            your live site and ensure
                                            functionality is preserved. If an
                                            update breaks some of your sites
                                            functionality we dedicate
                                            experienced WordPress engineers to
                                            resolve the issue. This is our
                                            definition of Managed.
                                        </p>
                                        <p className="is-size-5">
                                            We manage migration, this includes
                                            moving you from your current host,
                                            free SSL, and assistance in setting
                                            up DNS records at either cloudflare
                                            or Amazon Route 53.
                                        </p>
                                        <p className="is-size-5">
                                            We don't use Plesk or Cpanel, they
                                            are security risks. We can provide
                                            you server access as well as the
                                            ability to take backups to your own
                                            physical hard drive. We monitor your
                                            sites uptime, performance of
                                            automated tasks, and PHP error logs.
                                            We aggresively ban unusual access to
                                            your site, and scan for malware.
                                            We'll know of any issues well before
                                            your users do.
                                        </p>
                                    </div>
                                </div>
                                <div className="columns is-multiline">
                                    <Toggle
                                    toggleText={{
                                        expand: "Show me Tech Lingo",
                                        hide: "Hide the Tech Babble",
                                    }}
                                >   
                                    <div className="columns is-multiline">
                                        <div className="column is-full">
                                            <p className="is-size-1">
                                                Our Technologies and what we manage
                                            </p>
                                        </div>
                                        <div className="column is-third-desktop">
                                            <ul className="is-size-6">
                                                <li>Ubuntu 18.04</li>
                                                <li>MariaDB 10.2</li>
                                                <li>
                                                    Nginx 1.15 or Apache 2.4
                                                </li>
                                                <li>PHP 7.3 - 7.0</li>
                                                <li>Fail2Ban Firewall</li>
                                                <li>LetsEncrypt SSL</li>
                                            </ul>
                                            <ul className="is-size-6">
                                                <li>Wordfence </li>
                                                <li>Sucuri</li>
                                                <li>Uptime Robot</li>
                                                <li>Cronitor</li>
                                                <li>Rollbar</li>
                                            </ul>
                                        </div>
                                        <div className="column is-two-thirds-desktop">
                                            <p className="is-size-6">
                                                Dev time does not roll over from
                                                month to month. Dev time can
                                                only be used to fix issues
                                                related to upgrading, and
                                                preserving functionality, not
                                                adding functionality. If your
                                                incompatabilties are not
                                                resolved in this time, we will
                                                contact you requesting
                                                permission to continue at a rate
                                                of $100 nzd / hour. You are free
                                                to fix these issues yourself or
                                                receive support from an external
                                                developer, we will give you
                                                access to your staging
                                                environment.
                                            </p>
                                            <p className="is-size-6">
                                                The plugins that we include in
                                                our coverage is at our
                                                discretion. We include well
                                                respected and maintained plugins
                                                like WooCommerce and Ninja
                                                Forms. If you are using premium
                                                plugins we require valid
                                                licensing to the most up to date
                                                version before we attempt to
                                                resolve any compatabilitiy
                                                issues.
                                            </p>
                                            <p className="is-size-6">
                                                The same rules apply to themes.
                                                If the theme is a premium theme,
                                                we require valid licensing to
                                                the most up to date version
                                                before attempting to resolve any
                                                compatabilitiy issues. If you've
                                                made custom code changes to your
                                                theme, please ensure you're
                                                using child themes to do this,
                                                so that updating is possible.
                                            </p>
                                            <p className="is-size-6">
                                                Our Initial service offering is
                                                aimed at business owners rather
                                                than developers. We can give
                                                SFTP credentials (and SSH keys
                                                for dedicated servers) but we
                                                don't currently have a control
                                                panel offering, or
                                                click-to-stage service in
                                                production, although we are
                                                working on it.
                                            </p>
                                        </div>
                                    </div>
                                </Toggle>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="hero secondary" id="pricing">
                        <div className="hero-body">
                            <div className="container">
                                <div  className="columns is-multiline">
                                    <div className="column is-full">
                                        <p className="is-size-1">
                                            Monthly Pricing
                                        </p>
                                    </div>
                                    <div className="column is-one-third-desktop">
                                        <h2 className="is-size-4 is-uppercase">Shared</h2>
                                        <p className="is-size-6">
                                            3GB Hard drive space
                                            <br />
                                            30GB Bandwidth
                                            <br />
                                            Daily Database Backup
                                            <br />
                                            Daily File Backup
                                            <br />
                                            Sandbox User/Application
                                            <br />
                                        </p>
                                        <p className="is-size-6">
                                            Free:
                                            <br />
                                            SSL
                                            <br />
                                            Migration
                                            <br />
                                            15 minutes dev time per month
                                            <br />
                                        </p>
                                        <h2 className="is-size-4">
                                            $49
                                        </h2>
                                        <button
                                            className="button"
                                            onClick={() => {
                                                toggleModal("shared");
                                            }}
                                        >
                                            Sign up
                                        </button>
                                    </div>
                                    <div className="column is-one-third-desktop">
                                        <h2 className="is-size-4 is-uppercase">Upsize</h2>

                                        <p className="is-size-6">
                                            10GB Hard drive space
                                            <br />
                                            100GB Bandwidth
                                            <br />
                                            Hourly Database Backup
                                            <br />
                                            Daily File Backup
                                            <br />
                                            Sandbox User/Application
                                            <br />
                                        </p>
                                        <p className="is-size-6">
                                            Free:
                                            <br />
                                            SSL
                                            <br />
                                            Migration
                                            <br />
                                            30 minutes dev time per month
                                            <br />
                                        </p>
                                        <h2 className="is-size-4">
                                            $69
                                        </h2>
                                        <button
                                            className="button"
                                            onClick={() => {
                                                toggleModal("upsize");
                                            }}
                                        >
                                            Sign up
                                        </button>
                                    </div>
                                    <div className="column is-one-third-desktop">
                                        <h2 className="is-size-4 is-uppercase">Lone Wolf</h2>

                                        <p className="is-size-6">
                                            10GB Hard drive space
                                            <br />
                                            100GB Bandwidth
                                            <br />
                                            Hourly Database Backup
                                            <br />
                                            Daily File Backup
                                            <br />
                                            Your own server
                                            <br />
                                        </p>
                                        <p className="is-size-6">
                                            Free:
                                            <br />
                                            SSL
                                            <br />
                                            Migration
                                            <br />
                                            45 minutes dev time per month
                                            <br />
                                        </p>
                                        <h2 className="is-size-4">
                                            $89
                                        </h2>
                                        <button
                                            className="button"
                                            onClick={() => {
                                                toggleModal("lone-wolf");
                                            }}
                                        >
                                            Sign up
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="hero primary" id="section-3">
                        <div className="hero-body">
                            <div className="container">
                                <div className="columns is-multiline">
                                    <div className="column is-full">
                                        <p className="is-size-1">About Us</p>
                                    </div>
                                    <div className="column is-four-fifths-desktop">
                                        <p className="is-size-5">
                                            We're owned and operated in New
                                            Zealand, from Dunedin, Blenheim and
                                            Auckland. We offer a managed remedy
                                            for the sticking points that we have
                                            encountered on client sites. We're
                                            the only provider in New Zealand
                                            that offers developer time inclusive
                                            of our service. This is our
                                            investment to you, aiming to
                                            establish a long lasting
                                            relationship. We're not offering
                                            hardware, we're offering 99.999%
                                            uptime, better performance, and
                                            meticulous risk mitigation.
                                        </p>
                                    </div>
                                </div>
                                <div className="columns is-multiline">
                                    <div className="column is-full">
                                        <p className="is-size-1">Carl Aiau</p>
                                    </div>
                                    <div className="column is-four-fifths-quarters-desktop">
                                        <p className="is-size-5">
                                            I have been a Wordpress Developer since 2012, and have managed client sites since. 
                                            I'm obsessed with all aspects of computer science  from AI and Information Retrieval
                                            to frontend development and DevOps. Capitalising on our teams divese skillset gives us a competitive
                                            advantage when it comes to security and automation. This is how we can offer actual hands on hours, and why our competitors can't.
                                        </p>
                                    </div>
                                    <div className="column is-one-fifth-desktop">
                                        <Img
                                            fluid={carl.childImageSharp.fluid}
                                            width="100%"
                                            alt="Carl Aiau"
                                        />
                                    </div>
                                </div>
                                <div className="columns is-multiline">
                                    <div className="column is-full">
                                        <p className="is-size-1">
                                            Dominik Paschke
                                        </p>
                                    </div>
                                    <div className="column is-four-fifths-quarters-desktop">
                                        
                                        <p className="is-size-5">
                                            Originally a PhD qualified biologist, I have since converted to the joys of Web
                                            Development. I like building fast and secure websites using modern technology. I started out with
                                            WordPress and WooCommerce, while relying on minimal plugins. My current passions are
                                            modern Javascript technologies, such as Vue and React. For the backend, I am fond of working with Laravel.
                                        </p>
                                    </div>
                                    <div className="column is-one-fifth-desktop">
                                        <Img
                                            fluid={{
                                                ...dominik.childImageSharp.fluid,
                                                aspectRatio: 1
                                            }}
                                            width="100%"
                                            alt="Dominik Paschke"
                                        />
                                    </div>
                                </div>
                                <div className="columns is-multiline">
                                    <div className="column is-full">
                                        <p className="is-size-1">Steven Male</p>
                                    </div>
                                    <div className="column is-four-fifths-quarters-desktop">
                                        <p className="is-size-5">
                                        From billion dollar companies and those just starting up, I love helping New Zealanders change the world through marketing 
                                        and helping them launch their marketing efforts into the stratosphere. This is why I'm so excited about EZWP. 
                                        To launch a great marketing campaign, you need to start with a strong foundation and hosting is one of the very first steps.
                                        </p>
                                    </div>
                                    <div className="column is-one-fifth-desktop">
                                        <Img
                                            fluid={steven.childImageSharp.fluid}
                                            width="100%"
                                            alt="Steven Male"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        )}
    />
);

export default Content;
