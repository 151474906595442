import React from "react";
import { FaChevronDown } from "react-icons/fa";

import AnchorLink from "react-anchor-link-smooth-scroll";

const Header = ({ siteTitle }) => (
  <div>
    
    <section className="hero primary first is-fullheight" id="section-0">
      <div className="hero-body">
        <div className="container">
          <h1 className="is-size-1">Truly Managed<br/>WordPress hosting</h1>
          <hr/>
          <p className="subtitle is-size-2">
            EZWP is a faster, more reliable Wordpress hosting solution by New Zealanders for New Zealanders
          </p>
        </div>
        <AnchorLink className="more" href="#section-1">
          <FaChevronDown />
        </AnchorLink>
      </div>
    </section>
  </div>
);

export default Header;
